import React from "react"

const defaultState = {
    active: false
  }
  
  export const StripComponentContext = React.createContext(defaultState)

  export default class StripComponent extends React.Component {
    state = {
        active: false
    }

    handleClickOpen = () => {
        let isActive = this.state.active;
        if(!isActive)
        {
            this.setState({ active: !isActive });
        }
    };

    handleClickClose = () => {
        if(this.state.active)
        {
          let isActive = !this.state.active
          this.setState({ active: isActive })
        }
      }

    render() {
        const { children} = this.props
        const { active } = this.state
        return (
            <div className={"strips__strip  " + (active ? "strips__strip--expanded" : "")}
                onClick={this.handleClickOpen} onKeyDown={this.handleClickOpen} role="button" tabIndex="0">
                {children}
                <div className={"fa fa-close strip__close " + (active ? "strip__close--show" : "")} 
                onClick={this.handleClickClose} onKeyDown={this.handleClickClose} role="button" tabIndex="0">X</div>
            </div>
        )

  }
}
