import React from "react"
import { graphql } from 'gatsby';
import SEO from "../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import StripComponent from "../components/StripComponent"
import Carousel from 'react-bootstrap/Carousel'

class Impression3DPage extends React.Component  {

  render() {
    return (
    <section class="strips">
        <SEO title="Impression 3D" description="Un tour d'horizon de certaines de nos créations."/>
        <StripComponent>
          <div class="strip__content" >
            <div class="strip__title">
              <Img fluid={this.props.data.casque1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
            </div>
            <div class="strip__inner-text">
              <div class="container">
                
                <div class="page-header">
                  <p class="lead">Modélisation originale, basée sur le casque de Gimli dans le Seigneur des Anneaux avec l'ajout de symboles Protoss.</p>
                </div>
                <Carousel>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque2.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque3.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque4.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque5.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.casque6.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                    <Carousel.Caption>
                      <h3 class="BlueTitle">Merci @ Rémi "TKL" Hibon</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
                
              </div>
            </div>
            
          </div>
        </StripComponent>
        <StripComponent>
          <div class="strip__content">
            <div class="strip__title">
              <Img fluid={this.props.data.octo1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
            </div>
            <div class="strip__inner-text">
              <div class="container">
      
                <div class="page-header">
                  <p class="lead">Réalisation du trophée pour la compétition de l'Octogone sur Starcraft 2.</p>
                </div>
                    
                <Carousel>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo2.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo3.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo4.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo5.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.octo6.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </StripComponent>
        <StripComponent>
          <div class="strip__content">
            <div class="strip__title">
              <Img fluid={this.props.data.issy1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
            </div>
            <div class="strip__inner-text">
              <div class="container">
      
                <div class="page-header">
                  <p class="lead">Réalisation des trophées pour deux compétitions dans les locaux d'Ogaming TV, avec la mairie d'Ivry sur Seine.</p>
                </div>
                <Carousel>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy1.childImageSharp.fluid}  objectPosition="50% 50%" style={{position: "inherit"}}/>
                    <Carousel.Caption>
                      <h3 class="BlueTitle">Merci @ OgamingTV & Ivry sur Seine</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy2.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy3.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy4.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy5.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.issy6.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                    
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </StripComponent>
        <StripComponent>
          <div class="strip__content" >
            <div class="strip__title">
              <Img fluid={this.props.data.star3.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
            </div>
            <div class="strip__inner-text">
              <div class="container">
      
                <div class="page-header">
                  <p class="lead">Realisation d'une étoile de Sheriff inspirée de l'univers de Starcraft 2.</p>
                </div>
                <Carousel>
                  <Carousel.Item>
                    <Img fluid={this.props.data.star3.childImageSharp.fluid}  objectPosition="50% 50%" style={{position: "inherit"}}/>
                    <Carousel.Caption>
                      <h3 class="BlueTitle">Merci @ Anton "Algost" Bodin-Marty</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.star4.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.star1.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.star2.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </StripComponent>
        <StripComponent>
          <div class="strip__content" >
            <div class="strip__title">
              <Img fluid={this.props.data.tr1.childImageSharp.fluid} objectFit="cover" objectPosition="50% 50%" style={{position: "inherit"}}/>
            </div>
            <div class="strip__inner-text">
              <div class="container">
      
                <div class="page-header">
                  <p class="lead">Création des pièces d'un jeu de plateau pour l'emission 'Game of Troncs' sur la chaine de streaming Ogaming Starcraft 2.</p>
                </div>
                    
                <Carousel>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr1.childImageSharp.fluid}  objectPosition="50% 50%" style={{position: "inherit"}}/>
                    <Carousel.Caption>
                      <h3 class="BlueTitle">Merci @ OgamingTV </h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr2.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr3.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr4.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr5.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr6.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr7.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr8.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr9.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr10.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Img fluid={this.props.data.tr11.childImageSharp.fluid} objectPosition="50% 50%" style={{position: "inherit"}}/>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </StripComponent>
      </section>
        
    )
  }
}

export default Impression3DPage

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
        fluid (maxHeight: 1000){
          ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    casque1: file(relativePath: { eq: "casque1.png" }) {
      ...squareImage
    }
    casque2: file(relativePath: { eq: "casque2.png" }) {
      ...squareImage
    }
    casque3: file(relativePath: { eq: "casque3.png" }) {
      ...squareImage
    }
    casque4: file(relativePath: { eq: "casque4.jpg" }) {
      ...squareImage
    }
    casque5: file(relativePath: { eq: "casque5.png" }) {
      ...squareImage
    }
    casque6: file(relativePath: { eq: "casque6.png" }) {
      ...squareImage
    }
    tr1: file(relativePath: { eq: "tr1.png" }) {
      ...squareImage
    }
    tr2: file(relativePath: { eq: "tr2.png" }) {
      ...squareImage
    }
    tr3: file(relativePath: { eq: "tr3.png" }) {
      ...squareImage
    }
    tr4: file(relativePath: { eq: "tr4.png" }) {
      ...squareImage
    }
    tr5: file(relativePath: { eq: "tr5.png" }) {
      ...squareImage
    }
    tr6: file(relativePath: { eq: "tr6.png" }) {
      ...squareImage
    }
    tr7: file(relativePath: { eq: "tr7.png" }) {
      ...squareImage
    }
    tr8: file(relativePath: { eq: "tr8.png" }) {
      ...squareImage
    }
    tr9: file(relativePath: { eq: "tr9.png" }) {
      ...squareImage
    }
    tr10: file(relativePath: { eq: "tr10.png" }) {
      ...squareImage
    }
    tr11: file(relativePath: { eq: "tr11.jpg" }) {
      ...squareImage
    }
    octo1: file(relativePath: { eq: "octo1.png" }) {
      ...squareImage
    }
    octo2: file(relativePath: { eq: "octo2.png" }) {
      ...squareImage
    }
    octo3: file(relativePath: { eq: "octo3.png" }) {
      ...squareImage
    }
    octo4: file(relativePath: { eq: "octo4.png" }) {
      ...squareImage
    }
    octo5: file(relativePath: { eq: "octo5.png" }) {
      ...squareImage
    }
    octo6: file(relativePath: { eq: "octo6.png" }) {
      ...squareImage
    }
    issy1: file(relativePath: { eq: "issy1.png" }) {
      ...squareImage
    }
    issy2: file(relativePath: { eq: "issy2.png" }) {
      ...squareImage
    }
    issy3: file(relativePath: { eq: "issy3.png" }) {
      ...squareImage
    }
    issy4: file(relativePath: { eq: "issy4.png" }) {
      ...squareImage
    }
    issy5: file(relativePath: { eq: "issy5.png" }) {
      ...squareImage
    }
    issy6: file(relativePath: { eq: "issy6.png" }) {
      ...squareImage
    }
    star1: file(relativePath: { eq: "star1.png" }) {
      ...squareImage
    }
    star2: file(relativePath: { eq: "star2.png" }) {
      ...squareImage
    }
    star3: file(relativePath: { eq: "star3.png" }) {
      ...squareImage
    }
    star4: file(relativePath: { eq: "star4.png" }) {
      ...squareImage
    }
  }
`
